import { useProductsPublicGetExternalHeatpumpProducts } from '@dc/hooks';

import { getUnitSpecValue } from '../utils/getSpecification';

export const useHeatpumpProducts = (productId?: string) => {
  const { data } = useProductsPublicGetExternalHeatpumpProducts();

  // Filter out the specific heatpump based on the configured productId in Sitecore
  const filteredData = data?.products?.find(item => item?.id === productId);

  /*
    Flatten the response object to set al properties on root level.
    In this case the table can loop over this object and easily replace the Sitecore tokens with DC values.
  */
  const productData =
    filteredData &&
    Object.entries(filteredData ?? {}).reduce((acc: Record<string, string>, [productKey, productValue]) => {
      const spec =
        productValue instanceof Array
          ? productValue.reduce((acc: Record<string, string>, value) => {
              const unitSpecItem = Object.entries(value).map(([unitKey, unitValue]) => {
                return getUnitSpecValue(unitKey, unitValue, value);
              });

              return Object.assign(acc, ...unitSpecItem);
            }, {})
          : { [productKey]: productValue };

      return { ...acc, ...spec };
    }, {});

  return {
    data,
    filteredData,
    productData,
  };
};
