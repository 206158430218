import { Products_External_ProductDimensionsModel } from '@monorepo-types/dc';

export enum specNames {
  dimensionsMm = 'dimensionsMm',
  noiseDba = 'noiseDba',
}

export const getFieldMappingValue: Record<string, string> = {
  true: 'Ja',
  false: 'Nee',
};

// ToDo: Manage this mapping on Salesforce side
export const getHotTapWaterValue: Record<string, string> = {
  true: 'Warmtepomp',
  false: 'Cv-ketel',
};
/*
  Dimensions in the response are in an object notation and in millimeters
  Convert the dimensions into centimeters and create a string literal
*/
export const getFormattedDimensions = (dimensions: Products_External_ProductDimensionsModel) => {
  if (!dimensions || !dimensions.height || !dimensions.width || !dimensions.depth) return '';
  const convertToCm = (value: number) => (value / 10).toFixed(1).toLocaleString();

  return `${convertToCm(dimensions.height)} x ${convertToCm(dimensions.width)} x ${convertToCm(dimensions.depth)}`;
};

// Check for exceptions and call specific functions to render data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUnitSpecValue = (unitKey: string, unitValue: unknown, value: any) => {
  if (!unitKey || !unitValue) return {};

  let specValue;
  switch (unitKey) {
    case specNames.noiseDba:
      specValue = typeof unitValue === 'object' && 'maximum' in unitValue ? unitValue.maximum : null;
      break;
    case specNames.dimensionsMm:
      specValue = typeof unitValue === 'object' ? getFormattedDimensions(unitValue) : null;
      break;
    default:
      specValue = unitValue;
  }
  return {
    [`${value.type}.${unitKey}`]: specValue, // i.e. binnenunit.name: 'Remeha Elga ACE (6kW) binnenunit'
  };
};
