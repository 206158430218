import { FC } from 'react';

import { useContent } from '@sitecore/common';
import { ShopWindowRendering } from '@sitecore/types/manual/ShopWindow';
import { PageGrid, Grid, Heading, Box, Stretch } from '@sparky';

import ProductTile from './components/ProductTile';
import { useHeatpumpProducts } from './hooks/useHeatpumpProducts';

const ShopWindow: FC = () => {
  const {
    params: { backgroundColor },
    fields: { content },
    fields: {
      content: { productsList },
    },
  } = useContent<ShopWindowRendering>();
  const { data } = useHeatpumpProducts();

  return (
    <Box paddingY={{ initial: '10', md: '16' }}>
      <PageGrid>
        <PageGrid.Item gridColumn="1/-1">
          <Box paddingBottom="6">
            <Heading as="h2" size="M">
              {content.title.value}
            </Heading>
          </Box>
          <Grid as="ul" columns={{ initial: '1', md: '2', xl: '3' }} flow="dense" gap="10">
            {productsList.map(product => (
              <Grid.Item as="li" key={product.id}>
                <Stretch>
                  <ProductTile
                    item={product}
                    productData={
                      data?.products?.find(obj => obj?.id === product?.fields?.productProperties?.productId?.value) ??
                      {}
                    }
                    backgroundColor={backgroundColor}
                  />
                </Stretch>
              </Grid.Item>
            ))}
          </Grid>
        </PageGrid.Item>
      </PageGrid>
    </Box>
  );
};

export default ShopWindow;
